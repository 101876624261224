<template>
    <div>
        <RewardsHeader title="" style="z-index: 20"/>

        <b-row >
            <b-col cols="2" sm="2" md="5" lg="5"></b-col>
            <b-col cols="4" sm="4" md="2" lg="2" >
              <b-row>
                <b-col class="text-center justify-content-center">
                  <h3 class="h3 mx-auto bold title">REWARDS</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center justify-content-center">
                  <button class="outline-button" @click="goToHistory">View History</button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="5" sm="5" md="3" lg="3">
                <img src="../../../../assets/customer_rewards.png" class="banner"/>
            </b-col>
            <b-col cols="1" sm="1" md="2" lg="2"></b-col>
        </b-row>
        <!--content-->
        <b-row style="margin-top: 40px;">
            <b-col cols="1" sm="1" md="2" lg="2"></b-col>
            <b-col cols="10" sm="10" md="8" lg="8">
                <b-overlay :show="loading" rounded="sm">
                  <b-row class="y-list-item" v-for="(reward, idx) in getRewards" :key="idx" @click="onSelect(reward.id, reward.points, reward.name)">
                    <b-col cols="2" sm="2" md="1" lg="1">
                      <img src="../../../../assets/non_active_user.png" class="y-icon">
                    </b-col>
                    <b-col cols="8" sm="8" md="10" lg="10" class="m-auto">
                      <b-row>
                        <b-col cols="4" sm="5" md="3" lg="3" class="points text-center m-auto">
                          <b-row>
                            <div >
                              <p :class="(customer.details.current_points >= reward.points)?'pts-active':'pts'">{{reward.points}}</p>
                            </div>
                          </b-row>
                          <b-row>
                            <div >
                              <p :class="(customer.details.current_points >= reward.points)?'text-pts-active':'text-pts'">Points</p>
                            </div>
                          </b-row>
                        </b-col>
                        <b-col cols="8" sm="7" md="9" lg="9" class="m-auto">
                          <span class="name">{{reward.name}}</span>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" sm="2" md="1" lg="1"></b-col>
                  </b-row>
                </b-overlay>
            </b-col>
            <b-col cols="1" sm="1" md="2" lg="2"></b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RewardsHeader from "../components/RewardsHeader";
    import router from "../../../../app/router/router";

    export default {
        name: "Rewards",
        components: {RewardsHeader},
        data(){
            return{
                customer:JSON.parse(localStorage.getItem("customer")),
                loading:false
            }
        },
        mounted() {
          this.loading = true
          this.fetchRewards()
        },
        methods:{
            ...mapActions(["fetchRewards"]),
            onSelect(id, points, name){
                if(this.customer.details.current_points >= points){
                    let data = {
                        points: points,
                        reward_id: id,
                        name:name
                    }
                    localStorage.setItem("redeem", JSON.stringify(data))
                    router.push("/redeem-item")
                }
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            goToHistory(){
              router.push("/redeem-history")
            }
        },
        computed: {
            ...mapGetters([
                "getRewards",
            ]),
        },
        watch:{
          getRewards(){
            this.loading = false
          },
        }
    }
</script>

<style scoped>
    @import "../../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 80px;
    }

    .y-icon{
        margin: 10px;
        height: 90px;
        width: 95px;
    }

    .y-list-item{
        padding: 5px;
        text-align: center;
        border-bottom: solid 1px #a7a7a7;
    }

    .y-list-item:hover{
        background: #f1f1f1;
    }

    .pts{
        color: #a0a0a0;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        margin: 0;
        padding: 0;
        line-height: 40px;
    }

    .text-pts{
        color: #a0a0a0;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .pts-active{
        color: #A6C34C;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        margin: 0;
        padding: 0;
        line-height: 40px;
    }

    .text-pts-active{
        color: #A6C34C;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .less{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .outline-button{
      background: #fff;
      color: #c82333;
      padding: 5px;
      font-weight: bold;
      border: solid 2px #c82333;
      border-radius: 6px;
    }

    .outline-button:hover{
      background: #c82333;
      color: #fff;
    }

    .points{
        margin-top: 0px;
    }

    .name{
      float: left;
    }

    @media only screen and (max-width: 640px){
      .banner{
        width: 180px;
        margin-top: 10px;
      }
      .title{
        margin-top: 40px;
      }

      .y-icon{
        margin: 5px;
        height: 70px;
        width: 75px;
      }

      .pts{
        font-size: 25px;
      }

      .text-pts{
        font-size: 12px;
      }

      .pts-active{
        font-size: 25px;
      }

      .text-pts-active{
        font-size: 12px;
      }

      .points{
        margin-left: 10px;
      }

    }

    @media only screen and (max-width: 560px){
      .banner{
        width: 150px;
      }

      .title{
        font-size: 16px;
      }

      .y-icon{
        margin: 5px;
        height: 60px;
        width: 65px;
      }

      .pts{
        font-size: 20px;
        line-height: 30px;
      }

      .text-pts{
        font-size: 10px;
      }

      .pts-active{
        font-size: 20px;
        line-height: 30px;
      }

      .text-pts-active{
        font-size: 10px;
      }

      .name{
        font-size: 12px;
      }

    }

    @media only screen and (max-width: 480px){
      .outline-button{
        font-size: 12px;
        border: solid 1px #c82333;
      }
    }

    @media only screen and (max-width: 380px) {
      .title{
        font-size: 14px;
      }

      .name{
        font-size: 11px;
      }

      .y-icon{
        margin: 5px;
        height: 45px;
        width: 50px;
      }

      .pts{
        font-size: 18px;
        line-height: 20px;
      }

      .text-pts{
        font-size: 10px;
      }

      .pts-active{
        font-size: 18px;
        line-height: 20px;
      }

      .text-pts-active{
        font-size: 10px;
      }

      .points {
        margin-left: 0px;
      }

    }

    @media only screen and (max-width: 320px) {

    }
</style>